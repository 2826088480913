import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Input from "./Input";
import { asModuleKey, COCKTAIL_INPUTS, STUDY_TYPES } from "../../../shared/constant";
import SelectInput from "./SelectInput";
import { FormControlLabel, FormGroup, Stack, Switch, Typography } from "@mui/material";
import { selectLicensing } from "../../../store/reducers/licensing";

const defaultStudyTypes = Object.values(STUDY_TYPES);

export default function ExperimentSetup({ experimentId, setExperimentId, setStudyType, studyType, isChipOnly, setIsChipOnly, setTabValue }) {
  const licensing = useSelector(selectLicensing);
  const Modules = (licensing as any).Modules || {};

  const disabledConditions = defaultStudyTypes.filter((type) => !Modules[asModuleKey(type)]);

  // Initialize studyType with a valid value, or send an invalid tab index if none available
  useEffect(() => {
    if (disabledConditions.length === defaultStudyTypes.length) {
      if (studyType !== null) {
        setStudyType(null);
        setTabValue(-1);
      }
    } else {
      if (!studyType || disabledConditions.includes(studyType)) {
        const validStudyType = defaultStudyTypes.find((type) => !disabledConditions.includes(type));
        if (validStudyType && studyType !== validStudyType) {
          setStudyType(validStudyType);
        }
      }
    }
  }, [disabledConditions, studyType, setStudyType, setTabValue]);

  const handleStudyTypeChange = (values) => {
    const selectedType = values[0];
    if (!disabledConditions.includes(selectedType)) {
      setStudyType(selectedType);
    }
  };

  return (
    <Stack spacing={2}>
      {studyType === null ? (
        <Typography color="error">No valid study types are available. Please check your licensing settings.</Typography>
      ) : (
        <SelectInput
          inputLabel={COCKTAIL_INPUTS.studyType}
          placeholder="Select study type"
          inputChange={handleStudyTypeChange}
          value={studyType}
          chipIndex={""}
          multiple={false}
          defaultConditions={defaultStudyTypes}
          detailKey={COCKTAIL_INPUTS.studyType}
          disabledConditions={disabledConditions}
        />
      )}
      <Input value={experimentId} label="Experiment ID" onChange={setExperimentId} />
      <FormGroup sx={{ my: "2rem !important" }}>
        <FormControlLabel control={<Switch />} label={COCKTAIL_INPUTS.isChipOnly} onChange={(e) => setIsChipOnly(e?.target?.checked)} checked={isChipOnly} />
      </FormGroup>
    </Stack>
  );
}
