import React from "react";
import { Box, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { BIOAVAILABILITY_INPUTS, COCKTAIL_INPUTS } from "../../../../shared/constant";
import ChipInput from "../../Steps/ChipInput";
import SelectInput from "../../Steps/SelectInput";
import BasicInput from "../../Steps/BasicInput";
import TabPanel from "../../components/TabPanel";

const IVDosePage = ({ heading, handleChange, handleInputChange, inputValues, step, ACCEPTED_KEYS, classes, element }) => {
  return (
    <>
      <TabPanel value={step} index={step} key={step}>
        <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
          <Typography sx={classes.label} variant="body2">
            Chips with Basal Drug Addition
          </Typography>
          <MuiChipsInput
            addOnWhichKey={ACCEPTED_KEYS}
            value={element[BIOAVAILABILITY_INPUTS.chips]}
            onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.chips)}
            fullWidth
            hideClearAll
            placeholder="Enter 1 or more"
            inputProps={{
              value: inputValues[BIOAVAILABILITY_INPUTS.chips] || "",
              onBlur: (e) => {
                const value = e.target.value;
                if (value) {
                  const currentValues = element[BIOAVAILABILITY_INPUTS.chips] || [];
                  // handleChangeDrugs([...currentValues, value]);
                }
              },
              onChange: (event) => handleInputChange(event, BIOAVAILABILITY_INPUTS.chips),
            }}
          />
        </Box>
        <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
          <Typography sx={classes.label} variant="body2">
            Apical Sampling Time Points (hrs)
          </Typography>
          <MuiChipsInput
            addOnWhichKey={ACCEPTED_KEYS}
            value={element[BIOAVAILABILITY_INPUTS.apicalSamplingTimes]}
            onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.apicalSamplingTimes)}
            fullWidth
            hideClearAll
            placeholder="Enter 1 or more"
            inputProps={{
              value: inputValues[BIOAVAILABILITY_INPUTS.apicalSamplingTimes] || "",
              onBlur: (e) => {
                const value = e.target.value;
                if (value) {
                  const currentValues = element[BIOAVAILABILITY_INPUTS.apicalSamplingTimes] || [];
                  // handleChangeDrugs([...currentValues, value]);
                }
              },
              onChange: (event) => handleInputChange(event, BIOAVAILABILITY_INPUTS.apicalSamplingTimes),
            }}
          />
        </Box>
        <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
          <Typography sx={classes.label} variant="body2">
            Basal Sampling Time Points (hrs)
          </Typography>
          <MuiChipsInput
            addOnWhichKey={ACCEPTED_KEYS}
            value={element[BIOAVAILABILITY_INPUTS.basalSamplingTimes]}
            onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.basalSamplingTimes)}
            fullWidth
            hideClearAll
            placeholder="Enter 1 or more"
            inputProps={{
              value: inputValues[BIOAVAILABILITY_INPUTS.basalSamplingTimes] || "",
              onBlur: (e) => {
                const value = e.target.value;
                if (value) {
                  const currentValues = element[BIOAVAILABILITY_INPUTS.basalSamplingTimes] || [];
                  // handleChangeDrugs([...currentValues, value]);
                }
              },
              onChange: (event) => handleInputChange(event, BIOAVAILABILITY_INPUTS.basalSamplingTimes),
            }}
          />
        </Box>
        <Box mb={2}>
          <Grid container spacing={3}>
            <Grid xs={6} item key={`${BIOAVAILABILITY_INPUTS.gutDonor}${step}`}>
              <BasicInput
                key={`${heading}_${step}_${BIOAVAILABILITY_INPUTS.gutDonor}`}
                val={element[BIOAVAILABILITY_INPUTS.gutDonor]}
                index={step}
                onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.gutDonor)}
                detailKey={BIOAVAILABILITY_INPUTS.gutDonor}
                label={"Gut Donor"}
              />
            </Grid>
            <Grid xs={6} item key={`${BIOAVAILABILITY_INPUTS.apicalSampleVolume}${step}`}>
              <BasicInput
                key={`${heading}_${step}_${BIOAVAILABILITY_INPUTS.apicalSampleVolume}`}
                val={element[BIOAVAILABILITY_INPUTS.apicalSampleVolume]}
                index={step}
                onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.apicalSampleVolume)}
                detailKey={BIOAVAILABILITY_INPUTS.apicalSampleVolume}
                label={"Sample Volume Apical (uL)"}
              />
            </Grid>
            <Grid xs={6} item key={`${BIOAVAILABILITY_INPUTS.hepatocyteDonor}${step}`}>
              <BasicInput
                key={`${heading}_${step}_${BIOAVAILABILITY_INPUTS.hepatocyteDonor}`}
                val={element[BIOAVAILABILITY_INPUTS.hepatocyteDonor]}
                index={step}
                onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.hepatocyteDonor)}
                detailKey={BIOAVAILABILITY_INPUTS.hepatocyteDonor}
                label="Hepatocyte Donor"
              />
            </Grid>
            <Grid xs={6} item key={`${BIOAVAILABILITY_INPUTS.basalSampleVolume}${step}`}>
              <BasicInput
                key={`${heading}_${step}_${BIOAVAILABILITY_INPUTS.basalSampleVolume}`}
                val={element[BIOAVAILABILITY_INPUTS.basalSampleVolume]}
                index={step}
                onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.basalSampleVolume)}
                detailKey={BIOAVAILABILITY_INPUTS.basalSampleVolume}
                label="Sample Volume Basal (uL)"
              />
            </Grid>
          </Grid>
        </Box>
        <BasicInput
          key={`${heading}_${step}_${BIOAVAILABILITY_INPUTS.technicalReplicates}`}
          val={element[BIOAVAILABILITY_INPUTS.technicalReplicates]}
          index={step}
          onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.technicalReplicates)}
          detailKey={BIOAVAILABILITY_INPUTS.technicalReplicates}
          label={"Technical Replicates"}
        />
        <FormControlLabel
          control={<Switch />}
          label="Add Intracellular Condition properties"
          onChange={(e) => handleChange(e?.target?.checked, "selected", element[BIOAVAILABILITY_INPUTS.intracellular])}
          checked={element[BIOAVAILABILITY_INPUTS.intracellular]?.selected}
          sx={{
            my: 4,
          }}
        />
        {element[BIOAVAILABILITY_INPUTS.intracellular]?.selected && (
          <>
            <BasicInput
              key={`${heading}_${step}_intracellular_${BIOAVAILABILITY_INPUTS.lysisVolume}`}
              val={element[BIOAVAILABILITY_INPUTS.intracellular][BIOAVAILABILITY_INPUTS.lysisVolume]}
              index={step}
              onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.lysisVolume, element[BIOAVAILABILITY_INPUTS.intracellular])}
              detailKey={BIOAVAILABILITY_INPUTS.technicalReplicates}
              label={"Lysis Volume (mL)"}
            />
            <Box mb={2}>
              <Grid container spacing={3}>
                <Grid xs={6} item key={`${BIOAVAILABILITY_INPUTS.sampleVolume}${step}`}>
                  <BasicInput
                    key={`${heading}_${step}_intracellular_${BIOAVAILABILITY_INPUTS.sampleVolume}`}
                    val={element[BIOAVAILABILITY_INPUTS.intracellular][BIOAVAILABILITY_INPUTS.sampleVolume]}
                    index={step}
                    onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.sampleVolume, element[BIOAVAILABILITY_INPUTS.intracellular])}
                    detailKey={BIOAVAILABILITY_INPUTS.sampleVolume}
                    label="Sample Volume (uL)"
                  />
                </Grid>
                <Grid xs={6} item key={`${BIOAVAILABILITY_INPUTS.technicalReplicates}_intracellular_${step}`}>
                  <BasicInput
                    key={`${heading}_${step}_${BIOAVAILABILITY_INPUTS.technicalReplicates}`}
                    val={element[BIOAVAILABILITY_INPUTS.intracellular][BIOAVAILABILITY_INPUTS.technicalReplicates]}
                    index={step}
                    onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.technicalReplicates, element[BIOAVAILABILITY_INPUTS.intracellular])}
                    detailKey={BIOAVAILABILITY_INPUTS.technicalReplicates}
                    label="Technical Replicates"
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </TabPanel>
    </>
  );
};

export default IVDosePage;
