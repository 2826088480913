import { Box, FormControl, Select, OutlinedInput, Typography, Chip, MenuItem } from "@mui/material";
import React from "react";
import { CloseIcon } from "../../../shared/Icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectInputProps {
  inputLabel: string;
  placeholder: string;
  value: any;
  inputChange: (value: any, detailKey: string, chipIndex: string) => void;
  chipIndex: string;
  defaultConditions: string[];
  detailKey: string;
  handleDelete?: (index: number) => void;
  multiple?: boolean;
  disabledConditions?: string[];
}

export default function SelectInput({
  inputLabel,
  placeholder,
  value,
  inputChange,
  chipIndex,
  defaultConditions,
  detailKey,
  handleDelete,
  multiple = false,
  disabledConditions = [],
}: SelectInputProps) {
  const handleChange = (e) => {
    const selectedValue = e.target.value;

    // Filter out any disabled conditions
    const validSelectedValue = Array.isArray(selectedValue)
      ? selectedValue.filter((val) => !disabledConditions.includes(val))
      : !disabledConditions.includes(selectedValue)
        ? selectedValue
        : "";

    inputChange(multiple ? validSelectedValue : [validSelectedValue], detailKey, chipIndex);
  };

  const getValue = () => {
    if (typeof value === "string") {
      return disabledConditions.includes(value) ? null : value;
    }
    if (Array.isArray(value)) {
      return value.filter((val) => !disabledConditions.includes(val));
    }
    return value;
  };

  return (
    <Box mb={2}>
      <Typography variant="body2">{inputLabel}</Typography>
      <FormControl fullWidth>
        <Select
          multiple={multiple}
          displayEmpty
          value={getValue()}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!selected || (multiple && selected.length === 0) || getValue() === null || getValue()?.length === 0) {
              return <em>{placeholder}</em>;
            }

            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {(multiple ? selected : [selected]).map((value, index) => (
                  <Chip
                    onDelete={handleDelete ? (e) => handleDelete(index) : undefined}
                    deleteIcon={handleDelete ? <CloseIcon onMouseDown={(event) => event.stopPropagation()} /> : undefined}
                    key={value}
                    label={value}
                  />
                ))}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {defaultConditions?.map((name) => (
            <MenuItem key={name} value={name} disabled={disabledConditions.includes(name)}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
