import { Button, IconButton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import Right from "../../../../assets/svg/arrow-right.svg";
import Left from "../../../../assets/svg/arrow-left.svg";
import CheckIcon from "@mui/icons-material/Check";
import Delete from "../../../../assets/svg/delete.svg";
import Done from "../../../../assets/svg/check-circle.svg";
import ChevronRight from "../../../../assets/svg/chevron-right.svg";
import { PlusIcon } from "../../../../shared/Icons";
import vars from "../../../../styles/variables";

import { findPath, getValueByPath } from "./helper";
import { produce } from "immer";

const { inputBorderHoverColor, inputDisableBorder, white, tabTextColor, successColor } = vars;

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface TabWrapperProps {
  heading;
  children;
  experiment;
  step;
  maxSteps;
  handleChange;
  elements;
  elementIndex;
  handleTabChange?;
  onImportFromFileClick?;
  onResetDefaults?;
  itemDisplayText?;
  addButtonLabel?;
  updateExperiment;
  placeholder;
  editable?;
  defaultAddValue?;
  updateElementIndex;
}

const TabWrapper = ({
  heading,
  children,
  experiment,
  step,
  maxSteps,
  handleChange,
  elements,
  elementIndex,
  handleTabChange,
  onImportFromFileClick,
  onResetDefaults,
  itemDisplayText,
  addButtonLabel,
  updateExperiment,
  placeholder,
  editable,
  defaultAddValue,
  updateElementIndex,
}: TabWrapperProps) => {
  const classes = {
    root: {
      display: "flex",
      height: "100%",
    },

    sidebar: {
      width: "18.75rem",
      background: inputBorderHoverColor,
      borderRight: `0.0625rem solid ${inputDisableBorder}`,
      boxShadow: "0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03)",

      "& h3": {
        borderBottom: `0.0625rem solid ${inputDisableBorder}`,
        padding: "1.5rem",
        margin: 0,
      },
    },

    elements: {
      height: step === 0 ? "calc(100% - 17.3115rem)" : "calc(100% - 15rem)",
      overflow: "auto",

      "& .MuiButton-text": {
        height: "4.5rem",
        borderBottom: `0.0625rem solid ${inputDisableBorder}`,
        color: tabTextColor,
      },

      "& .MuiTabs-indicator": {
        width: "0.25rem",
        right: "auto",
      },

      "& .MuiTab-root": {
        justifyContent: "flex-start",
        height: "4.5rem",
        padding: "0 1rem 0 1.5rem",
        borderBottom: `0.0625rem solid ${inputDisableBorder}`,

        "&.Mui-selected": {
          background: white,
        },

        "&:not(:first-of-type)": {
          marginLeft: 0,
        },
      },
    },

    content: {
      width: "calc(100% - 18.75rem)",
      overflow: "auto",
    },

    footer: {
      display: "flex",
      "& .MuiButton-outlined": {
        padding: "0",
        marginRight: "0.5rem",
        flexShrink: 0,
        minWidth: "2.5rem",
      },
    },
  };

  const [editingItemId, setEditingItemId] = React.useState(null);
  const dummyRef = useRef(null);
  // const [elementIndex, updateElementIndex] = useState(elementIndex);

  useEffect(() => {
    if (dummyRef.current) {
      dummyRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [children]);

  const handleEditItemClick = (id) => {
    setEditingItemId(id);
  };

  const handleTextChange = (name, i) => {
    const path = findPath(experiment, elements[i]);
    const result = produce(experiment, (draft) => {
      const element = getValueByPath(draft, path);
      element.name = name;
    });
    updateExperiment(result);
  };

  const onAdd = () => {
    const path = findPath(experiment, elements);
    const result = produce(experiment, (draft) => {
      const collection = getValueByPath(draft, path);
      collection.push(JSON.parse(JSON.stringify(defaultAddValue)));
    });
    updateElementIndex(elements.length);
    updateExperiment(result);
  };

  const onRemove = (i) => {
    const path = findPath(experiment, elements);
    const result = produce(experiment, (draft) => {
      const collection = getValueByPath(draft, path);
      collection.splice(i, 1);
    });
    updateElementIndex(i - 1);
    updateExperiment(result);
  };

  const handleCheckIconClick = () => {
    setEditingItemId(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleCheckIconClick();
    }
  };

  const showDoneButton = step === maxSteps;

  const editableEntry = () => {
    return (
      elements?.length > 0 && (
        <Tabs orientation="vertical" value={elementIndex} onChange={handleTabChange}>
          {elements.map((obj, i) => (
            <Tab
              onClick={() => {
                updateElementIndex(i);
              }}
              key={i}
              label={
                <>
                  {i === editingItemId ? (
                    <TextField autoFocus onChange={(e) => handleTextChange(e.target.value, i)} value={obj?.name} onKeyPress={handleKeyPress} />
                  ) : (
                    <span onClick={() => handleEditItemClick(i)}>{obj?.name || `${itemDisplayText ? itemDisplayText : ""} ${placeholder} ${i + 1}`}</span>
                  )}
                  <Box display="flex" alignItems="center" ml="auto">
                    {i !== editingItemId ? (
                      <>
                        {elementIndex === i && elements.length > 1 && (
                          <IconButton size="small" onClick={() => onRemove(i)} sx={{ mr: 0.5 }}>
                            <Delete />
                          </IconButton>
                        )}
                        <ChevronRight />
                      </>
                    ) : (
                      <Box display="flex" ml={1.5} columnGap={0.5}>
                        <IconButton color="success" size="small" onClick={handleCheckIconClick}>
                          <CheckIcon sx={{ fontSize: "1.25rem", color: successColor }} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </>
              }
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      )
    );
  };

  const nonEditableEntry = () => {
    return (
      elements?.length > 0 && (
        <Tabs orientation="vertical" value={elementIndex} onChange={handleTabChange}>
          {elements?.map((obj, i) => (
            <Tab
              key={i}
              label={
                <>
                  {elements[i].name ? elements[i].name : `${placeholder} ${i + 1}`}
                  <Box display="flex" alignItems="center" ml="auto">
                    {elementIndex === i && elements.length > 1 && (
                      <IconButton onClick={() => onRemove(i)} sx={{ mr: 1 }}>
                        <Delete />
                      </IconButton>
                    )}
                    <ChevronRight />
                  </Box>
                </>
              }
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      )
    );
  };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.sidebar}>
        <Typography component="h3">
          {heading}
          {elements?.length > 0 && ` [${elements?.length}]`}
        </Typography>

        <Box sx={classes.elements}>
          {editable ? editableEntry() : nonEditableEntry()}
          {/* Dummy element to scroll into view */}
          <div ref={dummyRef} style={{ height: "1px" }} />
        </Box>

        {addButtonLabel && (
          <Box pt={3}>
            <Button disableRipple fullWidth onClick={onAdd} sx={{ color: tabTextColor }}>
              <PlusIcon className="left-icon" />
              {addButtonLabel}
            </Button>
          </Box>
        )}

        <Box sx={{ ...classes.footer, flexDirection: step === 0 ? "column" : "row" }} p={3}>
          {step !== 0 && (
            <Button variant="outlined" onClick={(e) => handleChange?.(step - 1)}>
              <Left />
            </Button>
          )}

          {step === 0 && (
            <>
              <Box mb={2}>
                <Button variant="outlined" component="label" fullWidth onClick={(e) => onResetDefaults(e)}>
                  Reset to defaults
                </Button>
              </Box>
              <Box mb={2}>
                <Button variant="outlined" component="label" fullWidth onClick={(e) => onImportFromFileClick(e)}>
                  Import from file
                </Button>
              </Box>
            </>
          )}

          <Button variant="contained" fullWidth onClick={(e) => handleChange?.(step + 1)}>
            {showDoneButton ? (
              <>
                <Done className="left-icon" />
                {"Done"}
              </>
            ) : (
              <>
                {"Next"}
                <Right className="right-icon" />
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Box sx={classes.content} p={3}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            const elementKey = (child.props as any).elementKey;
            const idx = elementIndex >= elements.length ? elements.length - 1 : elementIndex < 0 ? 0 : elementIndex;
            const props = {
              element: elementKey ? elements[idx][elementKey] : elements[idx],
              experiment: experiment,
              updateExperiment: updateExperiment,
              elementIndex: idx,
            };
            return React.cloneElement(child, props);
          }
          return child;
        })}
      </Box>
    </Box>
  );
};

export default TabWrapper;
