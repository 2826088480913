import React, { useState } from "react";
import { Box } from "@mui/material";
import { BIOAVAILABILITY_INPUTS, EXPERIMENT_INPUTS, SampleLocation, SampleLocationLtcPlus, STUDY_TYPES } from "../../../../shared/constant";
import vars from "../../../../styles/variables";
import ChipOnlyPage from "./ChipOnlyPage";
import TabWrapper from "../common/TabWrapper";
import { produce } from "immer";
import OralDosePage from "./OralDosePage";

import { bioavailabilitySolutionDefault } from "../../../../components/Experiment/importers/defaultValues";

import { findPath, getValueByPath } from "../common/helper";
import IVDosePage from "./IVDosePage";

export const BioavailabilitySteps = {
  EXPERIMENT_SETUP: "Experiment Setup",
  ORAL_DOSE: "Oral Dose",
  IV_DOSE: "IV Dose",
  CHIP_ONLY: "Chip Only",
};

const { inputDisableBorder, labelColor } = vars;
const KEYBOARD_KEY = {
  comma: ",",
  space: " ",
  enter: "Enter",
};

const ACCEPTED_KEYS = [KEYBOARD_KEY.enter, KEYBOARD_KEY.space, KEYBOARD_KEY.comma];

const classes = {
  root: {
    borderLeft: `0.25rem solid ${inputDisableBorder}`,
    paddingLeft: "1.5rem",
  },
  muiChipInput: {
    "& .MuiSvgIcon-root": {
      background: "url(../../../assets/svg/close.svg) no-repeat center",
      fontSize: "2rem",
      "& path": {
        display: "none",
      },
    },
  },
  label: {
    color: labelColor,
  },
};

const BioAvailabilityDetail = (props) => {
  const [inputValues, setInputValues] = useState({});
  const { heading, step, tab } = props;
  const { element, elementIndex, updateExperiment, experiment } = props;

  const handleInputChange = (event, key) => {
    setInputValues({ ...inputValues, [key]: event.target.value });
  };

  const handleChange = (value, key, target) => {
    const elt = target || element;
    const path = findPath(experiment, elt);
    const newExperiment = produce(experiment, (draft) => {
      const element = getValueByPath(draft, path);
      element[key] = value;
    });
    updateExperiment(newExperiment);
    setInputValues({ ...inputValues, [key]: "" });
  };

  // const handleDelete = (index, key, target) => {
  //   const elt = target || element;
  //   const path = findPath(experiment, elt);
  //   const newExperiment = produce(experiment, (draft) => {
  //     const element = getValueByPath(draft, path);
  //     element[key].splice(index, 1);
  //   });
  //   updateExperiment(newExperiment);
  // };

  const renderContent = () => {
    switch (tab) {
      case BioavailabilitySteps.ORAL_DOSE:
        return (
          <OralDosePage
            ACCEPTED_KEYS={ACCEPTED_KEYS}
            classes={classes}
            element={element}
            heading={heading}
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            inputValues={inputValues}
            step={step}
          />
        );

      case BioavailabilitySteps.IV_DOSE:
        return (
          <IVDosePage
            ACCEPTED_KEYS={ACCEPTED_KEYS}
            classes={classes}
            element={element}
            heading={heading}
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            inputValues={inputValues}
            step={step}
          />
        );
      case BioavailabilitySteps.CHIP_ONLY:
        return (
          <ChipOnlyPage
            ACCEPTED_KEYS={ACCEPTED_KEYS}
            classes={classes}
            element={element}
            heading={heading}
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            // handleMultipleInputDelete={handleDelete}
            inputValues={inputValues}
            elementIndex={elementIndex}
            step={step}
            experiment={experiment}
          />
        );
      default:
        return null;
    }
  };

  return <Box>{renderContent()}</Box>;
};

export const renderSteps = (step, tab, elementIndexes, experiment, updateExperiment, changeStep, changeElementIndex) => {
  const maxSteps = Object.keys(BioavailabilitySteps).length - 1;
  const effectiveMaxSteps = experiment[EXPERIMENT_INPUTS.isChipOnly] ? maxSteps : maxSteps - 1;
  // const [elementIndexes, setElementIndexes] = useState({
  //   solution: tabElementIndex,
  // });

  // const updateElementIndex = (key, index) => {
  //   setElementIndexes(
  //     produce(elementIndexes, (draft) => {
  //       draft[key] = index;
  //     }),
  //   );
  //   changeElementIndex(index);
  // };

  switch (tab) {
    case BioavailabilitySteps.ORAL_DOSE:
      return (
        <TabWrapper
          elements={experiment[BIOAVAILABILITY_INPUTS.solutions]}
          heading={`${step + 1}. ${tab}`}
          elementIndex={elementIndexes[BIOAVAILABILITY_INPUTS.solutions] || 0}
          step={step}
          maxSteps={effectiveMaxSteps}
          updateExperiment={updateExperiment}
          experiment={experiment}
          addButtonLabel="Add a solution"
          placeholder="Solution"
          editable
          defaultAddValue={bioavailabilitySolutionDefault}
          handleChange={changeStep}
          updateElementIndex={(index) => changeElementIndex(STUDY_TYPES.Bioavailability, BIOAVAILABILITY_INPUTS.solutions, index)}
        >
          <BioAvailabilityDetail elementKey={BIOAVAILABILITY_INPUTS.oralDose} heading={`${step + 1}. ${tab}`} step={step} tab={tab} />
        </TabWrapper>
      );

    case BioavailabilitySteps.IV_DOSE:
      return (
        <TabWrapper
          elements={experiment[BIOAVAILABILITY_INPUTS.solutions]}
          heading={`${step + 1}. ${tab}`}
          elementIndex={elementIndexes[BIOAVAILABILITY_INPUTS.solutions] || 0}
          step={step}
          maxSteps={effectiveMaxSteps}
          updateExperiment={updateExperiment}
          experiment={experiment}
          placeholder="Solution"
          editable
          handleChange={changeStep}
          updateElementIndex={(index) => changeElementIndex(STUDY_TYPES.Bioavailability, BIOAVAILABILITY_INPUTS.solutions, index)}
        >
          <BioAvailabilityDetail elementKey={BIOAVAILABILITY_INPUTS.ivDose} heading={`${step + 1}. ${tab}`} step={step} tab={tab} />
        </TabWrapper>
      );

    case BioavailabilitySteps.CHIP_ONLY:
      return (
        <TabWrapper
          elements={experiment[BIOAVAILABILITY_INPUTS.solutions]}
          heading={`${step + 1}. ${tab}`}
          elementIndex={elementIndexes[BIOAVAILABILITY_INPUTS.solutions] || 0}
          step={step}
          maxSteps={effectiveMaxSteps}
          updateExperiment={updateExperiment}
          experiment={experiment}
          placeholder="Solution"
          editable
          handleChange={changeStep}
          updateElementIndex={(index) => changeElementIndex(STUDY_TYPES.Bioavailability, BIOAVAILABILITY_INPUTS.solutions, index)}
        >
          <BioAvailabilityDetail elementKey={BIOAVAILABILITY_INPUTS.chiponly} heading={`${step + 1}. ${tab}`} step={step} tab={tab} />
        </TabWrapper>
      );
    default:
      return null;
  }
};

export default BioAvailabilityDetail;
