import React from "react";
import Input from "./Input";

interface IInput {
  val: any;
  onChange: any;
  onBlur?: any;
  index: number;
  detailKey: string;
  mb?: number;
  label?: string;
  disabled?: boolean;
}

const BasicInput: React.FC<IInput> = ({ val, onChange, index, detailKey, mb, label, disabled, onBlur }) => {
  return <Input disabled={disabled} mb={mb} label={label || detailKey} value={val} onChange={(val) => onChange(val, detailKey, index)} onBlur={onBlur} />;
};

export default BasicInput;
