import React from "react";
import { Box, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { BIOAVAILABILITY_INPUTS } from "../../../../shared/constant";
import BasicInput from "../../Steps/BasicInput";
import vars from "../../../../styles/variables";
import SelectInput from "../common/SelectInput";

const ChipOnlyPage = ({
  heading,
  handleChange,
  handleInputChange,
  inputValues,
  step,
  ACCEPTED_KEYS,
  classes,
  element,
  // handleMultipleInputDelete,
  experiment,
  elementIndex,
}) => {
  const solution = experiment[BIOAVAILABILITY_INPUTS.solutions][elementIndex];
  return (
    <>
      <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
        <Typography sx={classes.label} variant="body2">
          Sample Collection Times (hrs)
        </Typography>
        <MuiChipsInput
          addOnWhichKey={ACCEPTED_KEYS}
          value={element[BIOAVAILABILITY_INPUTS.samplingTimes]}
          onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.samplingTimes)}
          fullWidth
          hideClearAll
          placeholder="Enter 1 or more"
          inputProps={{
            value: inputValues[BIOAVAILABILITY_INPUTS.samplingTimes] || "",
            onBlur: (e) => {
              const value = e.target.value;
              if (value) {
                const currentValues = element[BIOAVAILABILITY_INPUTS.samplingTimes] || [];
                // handleChangeDrugs([...currentValues, value]);
              }
            },
            onChange: (event) => handleInputChange(event, BIOAVAILABILITY_INPUTS.samplingTimes),
          }}
        />
      </Box>
      <BasicInput
        key={`${heading}_${step}_chiponly_${BIOAVAILABILITY_INPUTS.sampleVolume}`}
        val={element[BIOAVAILABILITY_INPUTS.sampleVolume]}
        index={step}
        onChange={(val, key) => handleChange(val, key)}
        detailKey={BIOAVAILABILITY_INPUTS.sampleVolume}
        label={"Sample Volume (uL)"}
      />
      <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
        <Typography sx={classes.label} variant="body2">
          chips
        </Typography>
        <MuiChipsInput
          addOnWhichKey={ACCEPTED_KEYS}
          value={element[BIOAVAILABILITY_INPUTS.chips]}
          onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.chips)}
          fullWidth
          hideClearAll
          placeholder="Enter 1 or more"
          inputProps={{
            value: inputValues[BIOAVAILABILITY_INPUTS.chips] || "",
            onBlur: (e) => {
              const value = e.target.value;
              if (value) {
                const currentValues = element[BIOAVAILABILITY_INPUTS.chips] || [];
                // handleChangeDrugs([...currentValues, value]);
              }
            },
            onChange: (event) => handleInputChange(event, BIOAVAILABILITY_INPUTS.chips),
          }}
        />
      </Box>
      {/* <SelectInput
        inputLabel="Basal Chips"
        placeholder="Select one or multiple linked chips"
        // inputChange={handleChange}
        inputChange={(value, key) => handleChange(value, key)}
        value={element[BIOAVAILABILITY_INPUTS.basalchips]}
        chipIndex={step}
        multiple={true}
        defaultConditions={solution[BIOAVAILABILITY_INPUTS.ivDose][BIOAVAILABILITY_INPUTS.chips]}
        detailKey={BIOAVAILABILITY_INPUTS.basalchips}
        handleDelete={(chipIndex, key) => handleMultipleInputDelete(chipIndex, key)}
      />
      <SelectInput
        inputLabel="Apical Chips"
        placeholder="Select one or multiple linked chips"
        // inputChange={handleChange}
        inputChange={(value, key) => handleChange(value, key)}
        value={element[BIOAVAILABILITY_INPUTS.apicalchips]}
        chipIndex={step}
        multiple={true}
        defaultConditions={solution[BIOAVAILABILITY_INPUTS.oralDose][BIOAVAILABILITY_INPUTS.chips]}
        detailKey={BIOAVAILABILITY_INPUTS.apicalchips}
        handleDelete={(index, key) => handleMultipleInputDelete(index, key)}
      /> */}
      <BasicInput
        key={`${heading}_${step}_chiponly_${BIOAVAILABILITY_INPUTS.technicalReplicates}`}
        val={element[BIOAVAILABILITY_INPUTS.technicalReplicates]}
        index={step}
        onChange={(val) => handleChange(val, BIOAVAILABILITY_INPUTS.technicalReplicates)}
        detailKey={BIOAVAILABILITY_INPUTS.technicalReplicates}
        label={"Technical Replicates"}
      />
      <Typography sx={vars.label} variant="body2">
        Optional: use chip only samples when assessing nonspecific binding
      </Typography>
    </>
  );
};

export default ChipOnlyPage;
