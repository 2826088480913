import React from "react";
import HepaticClearanceDetail from "../studyTypes/HepaticClearance/index";
import DDIInhibitionDetail from "../studyTypes/DDIInhibition/index";
import DDIInducerDetail from "../studyTypes/DDIInducer/index";
import { STUDY_TYPES } from "../../../shared/constant";
import BioAvailabilityDetail from "../studyTypes/Bioavailability";

const studyDetails = {
  [STUDY_TYPES.Clearence]: HepaticClearanceDetail,
  [STUDY_TYPES.Inhibition]: DDIInhibitionDetail,
  [STUDY_TYPES.Inducer]: DDIInducerDetail,
  [STUDY_TYPES.Bioavailability]: BioAvailabilityDetail,
};

const ExperimentDetail = ({ studyType, ...props }) => {
  const Component = studyDetails[studyType];
  return <Component {...props} />;
};

export default ExperimentDetail;
