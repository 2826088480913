export const findPath = (obj, target, path = []) => {
  if (obj === target) {
    return path;
  }

  if (typeof obj !== "object" || obj === null) {
    return null;
  }

  for (const key in obj) {
    const result = findPath(obj[key], target, [...path, key]);
    if (result) {
      return result;
    }
  }

  return null;
};

export const getValueByPath = (root, path) => {
  return path.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), root);
};
